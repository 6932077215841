<template>
  <svg width="161" height="28" viewBox="0 0 694.20001 120.46001" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
    <path class="letter" d="M 148.8,8.6599999 C 146.3,5.76 143.4,3.36 140.2,1.36 c -0.2,0 -0.3,-0.2 -0.5,-0.2 -0.2,0 -0.4,0 -0.5,0.2 -0.2,0.2 -0.4,0.5 -0.5,0.8 -0.8,1.5 -1.6,3.1 -2.4,4.5999999 C 129.2,20.06 122.3,33.46 115.2,46.66 c -3.4,6.3 -6.7,12.7 -10,19 -1.6,3 -3,6 -4.2,9.2 -1.699997,4.5 -3.099997,9.1 -3.699997,13.9 -0.700003,5.5 -0.200003,10.900004 1.8,16.2 0.2,0.6 0.5,1.3 0.8,1.9 1.699997,3.3 3.799997,6.3 6.399997,8.9 1.8,1.9 4,3.4 6.3,4.7 0.2,-0.1 0.4,-0.2 0.5,-0.3 0.2,-0.4 0.4,-0.8 0.6,-1.1 10.4,-19.799996 20.9,-39.6 31.3,-59.5 0.3,-0.5 0.5,-1 0.7,-1.5 0.4,-0.8 0.7,-1.6 1.1,-2.4 1.7,-3.3 3.3,-6.6 4.6,-10 1.8,-4.5 3.1,-9.2 3.7,-14 0.4,-3.1 0.4,-6.2 0,-9.3 -0.8,-5 -2.8,-9.5 -6.1,-13.4000001 z" id="path1" />
    <path class="letter" d="m 98.400003,52.36 c 0.8,0.9 2.299997,0.9 3.099997,0 0.3,-0.3 0.5,-0.7 0.7,-1.1 2,-3.6 3.9,-7.2 5.9,-10.8 0.3,-0.5 0.5,-1.1 0.8,-1.7 0.5,-1.3 0.5,-2.7 0,-4 -0.3,-0.9 -0.7,-1.7 -1.1,-2.6 -1.9,-3.6 -3.8,-7.1 -5.7,-10.7 -1.6,-3.1 -3.199997,-6.2 -4.899997,-9.2 C 96.9,11.76 96.6,11.16 96.3,10.66 95.9,9.9599999 95.3,9.5599999 94.5,9.4599999 c -0.7,0 -1.4,-0.1 -2,-0.2 -2.2,0 -4.5,0 -6.7,0 -2.2,0 -4.4,0 -6.5,0 -0.4,0 -0.9,0 -1.3,0 -0.4,0 -0.7,0.1 -1.1,0.3 -0.5,0.2 -0.8,0.6000001 -0.8,1.1000001 0,0.5 0,1 0.2,1.5 0.3,0.5 0.5,1 0.8,1.5 6.6,12 13.2,24.1 19.8,36.1 0.300003,0.5 0.600003,1.1 0.900003,1.6 0.2,0.3 0.4,0.6 0.6,0.9 z" id="path2" />
    <path class="letter" d="m 219.6,57.26 c -4.8,-2.3 -11,-4.7 -18.6,-7.3 -2.1,-0.8 -4.4,-1.8 -6.9,-3 -4.3,-2 -7.1,-3.4 -8.6,-4.3 -3.6,-2.3 -5.4,-4.9 -5.4,-8 0,-3.1 0.8,-3.9 2.4,-5.8 1.6,-1.9 3.8,-3.5 6.6,-4.9 3.7,-1.4 7.7,-2.2 11.9,-2.2 4.2,0 7.2,0.5 10.8,1.5 3.1,0.8 6.7,2.4 11,4.8 0.5,0.3 1,0.5 1.5,0.5 0.5,0 1.1,-0.2 1.4,-0.5 l 0.2,-0.2 1.8,-2.5 c 3,-4.2 4.7,-6.9 5.2,-8 0.1,-0.2 0,-0.6 -0.3,-1.1 -0.3,-0.5 -0.7,-1 -1.2,-1.4 -2.8,-2.4 -5.8,-4.4 -8.9,-6.0000001 -3.4,-1.6 -7.4,-2.8 -12,-3.5999999 -5.2,-1.2 -10,-1.4 -14.4,-1 -0.1,0 -0.3,0 -0.4,0 -0.4,0 -0.8,0 -1.2,0.1 -0.3,0 -0.6,0 -0.9,0.1 0,0 0,0 -0.1,0 -7.5,1 -13.4,3.5999999 -17,5.6 -0.8,0.4 -1.5,0.8 -2.2,1.3 -1.1,0.7 -1.6,1.1 -1.6,1.1 -2.6,1.9 -4.8,4.1 -6.5,6.6 -2.1,2.9 -3.5,6.1 -4.2,9.6 -0.6,2.2 -0.9,4.5 -0.9,6.8 0,4.8 1.4,9.5 4.3,14 2.4,3.4 6,6.2 10.9,8.6 4.8,2.3 11,4.7 18.6,7.3 2.1,0.8 4.4,1.8 6.9,3 4.3,2 7.1,3.4 8.6,4.3 3.6,2.3 5.4,4.9 5.4,8 0,3.1 -0.8,3.9 -2.4,5.8 -1.6,1.9 -3.8,3.5 -6.6,4.9 -3.7,1.4 -7.7,2.2 -11.9,2.2 -4.2,0 -7.2,-0.5 -10.8,-1.5 -3.1,-0.8 -6.7,-2.4 -11,-4.8 -0.5,-0.3 -1,-0.5 -1.5,-0.5 -0.5,0 -1.1,0.2 -1.4,0.5 l -0.2,0.2 -1.8,2.5 c -3,4.2 -4.7,6.900004 -5.2,8.000004 -0.1,0.2 0,0.6 0.3,1.1 0.3,0.5 0.7,0.999996 1.2,1.399996 2.8,2.4 5.8,4.4 8.9,6 3.5,1.6 7.6,2.9 12.3,3.7 4.7,0.8 9.6,1.1 14.5,0.9 6.8,-0.7 12.8,-2.6 18,-5.6 5.2,-3 9.3,-6.799996 12.3,-11.3 2.9,-4.5 4.4,-9.3 4.4,-14.3 0,-5 -1.4,-9.5 -4.3,-14 -2.4,-3.4 -6,-6.2 -10.9,-8.6 z" id="path3" />
    <path class="letter" d="m 58.6,57.259 c -4.8,-2.3 -11,-4.7 -18.6,-7.3 -2.1,-0.8 -4.4,-1.8 -6.9,-3 -4.3,-2 -7.1,-3.4 -8.6,-4.3 -3.6,-2.3 -5.4,-4.9 -5.4,-8 0,-3.1 0.8,-3.9 2.4,-5.8 1.6,-1.9 3.8,-3.5 6.6,-4.9 3.7,-1.4 7.7,-2.2 11.9,-2.2 4.2,0 7.2,0.5 10.8,1.5 3.1,0.8 6.7,2.4 11,4.8 0.5,0.3 1,0.5 1.5,0.5 0.5,0 1.1,-0.2 1.4,-0.5 l 0.2,-0.2 1.8,-2.5 c 3,-4.2 4.7,-6.9 5.2,-8 0.1,-0.2 0,-0.6 -0.3,-1.1 -0.3,-0.5 -0.7,-1 -1.2,-1.4 -2.8,-2.4 -5.8,-4.4 -8.9,-6.0000001 -3.5,-1.6 -7.6,-2.9 -12.3,-3.6999999 -4.7,-0.8 -9.6,-1.1 -14.5,-0.9 -6.8,0.7 -12.8,2.5999999 -18,5.5999999 C 11.5,12.859 7.4000002,16.659 4.4,21.159 c -2.9,4.5 -4.4,9.3 -4.4,14.3 0,5 1.4,9.5 4.3,14 2.4,3.4 6,6.2 10.9,8.6 4.8,2.3 11,4.7 18.6,7.3 2.1,0.8 4.4,1.8 6.9,3 4.3,2 7.1,3.4 8.6,4.3 3.6,2.3 5.4,4.9 5.4,8 0,3.1 -0.8,3.9 -2.4,5.8 -1.6,1.9 -3.8,3.5 -6.6,4.9 -3.7,1.4 -7.7,2.2 -11.9,2.2 -4.2,0 -7.2,-0.5 -10.8,-1.5 -3.1,-0.8 -6.7,-2.4 -11,-4.8 -0.5,-0.3 -1,-0.5 -1.5,-0.5 -0.5,0 -1.0999998,0.2 -1.3999998,0.5 l -0.2,0.2 L 7.1,89.959 c -3,4.2 -4.7,6.900004 -5.2,8.000004 -0.1,0.2 0,0.6 0.3,1.1 0.3,0.5 0.7,0.999996 1.2,1.399996 2.8,2.4 5.8000002,4.4 8.9,6 3.4,1.6 7.4,2.8 12,3.6 5.2,1.2 10,1.4 14.4,1 0.1,0 0.3,0 0.4,0 0.4,0 0.8,0 1.2,-0.1 0.3,0 0.6,0 0.9,-0.1 0,0 0,0 0.1,0 7.5,-1 13.4,-3.6 17,-5.6 0.8,-0.4 1.5,-0.8 2.2,-1.3 1.1,-0.7 1.6,-1.1 1.6,-1.1 2.6,-1.9 4.8,-4.099996 6.5,-6.599996 C 70.7,93.359 72.1,90.159 72.8,86.659 c 0.6,-2.2 0.9,-4.5 0.9,-6.8 0,-4.8 -1.4,-9.5 -4.3,-14 -2.4,-3.4 -6,-6.2 -10.9,-8.6 z" id="path4" />
    <path class="letter" d="m 507.6,52.761 c -4.1,-15.1 -8.3,-30.1 -12.4,-45.2000001 -0.2,-0.7 -0.4,-1.4 -0.6,-2.0999999 -0.5,-1.3 -1.5,-2 -2.8,-2.2 -1,-0.1 -2,-0.2 -2.9,-0.2 -4.6,0 -9.1,0 -13.7,0 -4.7,0 -9.4,0 -14,0 -1,0 -2,0 -2.9,0.2 -1.3,0.2 -2.2,1 -2.7,2.3 -0.4,0.9999999 -0.7,2.0999999 -0.9,3.1999999 C 448.5,31.161 442.4,53.661 436.3,76.061 c -0.9,3.3 -1.8,6.7 -2.6,10 -0.4,1.7 0.2,2.6 2,2.6 0.4,0 0.7,0 1.1,0 4.1,0 8.1,0 12.2,0 0.4,0 0.7,0 1.1,0 2.2,-0.1 3.2,-0.8 3.8,-2.8 0.3,-0.8 0.5,-1.7 0.7,-2.5 0.5,-1.7 1,-3.3 1.4,-5 0.4,-1.7 2.4,-3.2 4.1,-3.2 0.7,0 1.5,0 2.2,0 8.6,0 17.2,0 25.9,0 0.7,0 1.5,0 2.2,0 2,0.1 3.4,1.2 4.2,3.1 0.3,0.8 0.5,1.6 0.7,2.5 1.3,4.8 2.5,9.5 3.8,14.3 0.9,3.300004 1.8,6.7 2.7,10 0.8,2.9 1.6,3.5 4.7,3.5 4.3,0 8.6,0 12.9,0 0.5,0 1,0 1.5,0 0.8,0 1.6,-1.1 1.4,-1.8 -0.3,-1.2 -0.6,-2.4 -0.9,-3.6 -4.6,-16.7 -9.2,-33.5 -13.7,-50.2 z m -21.3,5.9 c -0.7,0.1 -1.5,0.1 -2.2,0.1 -3.1,0 -6.1,0 -9.2,0 -3.1,0 -6.1,0 -9.2,0 -0.6,0 -1.2,0 -1.8,-0.1 -1.2,-0.2 -2.1,-1.6 -1.9,-2.8 0.3,-1.2 0.6,-2.4 0.9,-3.6 1.6,-5.4 3.2,-10.8 4.8,-16.3 1,-3.5 2,-7.1 2.9,-10.7 0.3,-1.1 0.8,-2 1.8,-2.7 1.9,-1.5 5.8,-1.2 7.1,2 0.4,1 0.6,2.1 0.9,3.2 1.3,4.7 2.5,9.5 3.9,14.2 1,3.5 2.3,7 3.2,10.6 0.3,1.1 0.6,2.1 0.8,3.2 0.3,1.1 -0.8,2.6 -1.9,2.7 z" id="path5" />
    <path class="letter" d="m 612,3.361 c -0.8,-0.1 -1.7,-0.3 -2.5,-0.3 -6.2,0 -12.3,0 -18.5,0 -0.4,0 -0.7,0 -1.1,0 -1.7,0 -3.1,0.6 -4.2,1.9 -1.2,1.3999999 -2.4,2.7999999 -3.6,4.1999999 C 574.3,18.261 566.6,27.361 558.8,36.461 c -0.8,0.9 -1.7,1.8 -2.5,2.7 0,0 -0.3,0.1 -0.3,0 -0.2,-0.1 -0.5,-0.3 -0.5,-0.5 0,-1.1 -0.1,-2.2 -0.1,-3.3 0,-8.7 0,-17.5 0,-26.2000001 0,-1 0,-2 0,-3 C 555.4,4.061 554.3,3.061 552.1,2.961 c -0.4,0 -0.7,0 -1.1,0 -4.3,0 -8.6,0 -12.9,0 -0.6,0 -1.2,0 -1.8,0.1 -1.3,0.2 -2.3,1.1 -2.3,2.5 0,1.1999999 0,2.4999999 0,3.6999999 C 534,24.761 534,40.261 534,55.861 c 0,15.6 0,30.5 0,45.8 0,1.1 0,2.2 0,3.3 0,0.5 0,1 0.2,1.5 0.2,0.9 0.8,1.5 1.7,1.8 0.7,0.2 1.5,0.2 2.2,0.2 4.4,0 8.9,0 13.3,0 0.5,0 1,0 1.5,0 1.5,-0.1 2.6,-1.1 2.7,-2.6 0,-1.1 0,-2.2 0,-3.3 0,-8.4 0,-16.8 0,-25.1 0,-3.9 1.2,-7.1 3.8,-10.1 12,-13.9 23.9,-27.9 35.8,-41.8 5.4,-6.3 10.7,-12.5 16.1,-18.8000001 0.6,-0.7 1,-1.3999999 1.5,-2.0999999 0.2,-0.3 0,-1.2 -0.5,-1.2 z" id="path6" />
    <path class="letter" d="m 418.3,90.759 c -1.1,0 -2.2,0 -3.3,0 -15,0 -30.1,0 -45.1,0 -1.1,0 -2.2,0 -3.3,0 -1.5,0 -2.8,-1.3 -2.8,-2.9 0,-5.5 0,-11.1 0,-16.6 0,-1.4 1.1,-2.5 2.6,-2.6 1.1,0 2.2,0 3.3,0 10.7,0 21.4,0 32.1,0 1.1,0 2.2,0 3.3,0 1.7,0 2.6,-1.1 2.7,-2.9 0,-0.2 0,-0.5 0,-0.7 0,-3.4 0,-6.9 0,-10.3 0,-0.5 0,-1 0,-1.5 -0.1,-1.1 -1.1,-2.1 -2.2,-2.2 -1.1,0 -2.2,0 -3.3,0 -10.6,0 -21.2,0 -31.8,0 -1.1,0 -2.2,0 -3.3,0 -0.5,0 -1,-0.1 -1.4,-0.2 -1.1,-0.3 -1.9,-1.3 -2,-2.4 0,-0.6 0,-1.2 0,-1.8 0,-2.1 0,-4.2 0,-6.3 0,-0.9 0,-1.7 0,-2.6 0,-1.8 -1,-2.8 -2.7,-2.9 -0.4,0 -0.7,0 -1.1,0 -3.4,0 -6.9,0 -10.3,0 -0.4,0 -0.7,0 -1.1,0 -1.6,0.1 -2.6,1 -2.7,2.5 0,0.9 0,1.7 0,2.6 0,21.4 0,42.9 0,64.3 0,0.9 0,1.7 0.1,2.6 0.1,1.4 1.2,2.5 2.7,2.6 1.1,0 2.2,0 3.3,0 10.5,0 20.9,0 31.4,0 10.5,0 9.4,0 14,0 6.2,0 12.3,0 18.5,0 1,0 2,0 2.9,-0.2 0.9,-0.1 1.9,-1.1 2,-2 0,-0.7 0.1,-1.5 0.1,-2.2 0,-3 0,-5.899996 0,-8.899996 0,-0.900004 0,-1.700004 0,-2.600004 0,-1.3 -1.1,-2.4 -2.3,-2.5 z" id="path7" />
    <path class="logo" d="m 277.8,29.461 c -0.8,-3.9 -1.9,-7.7 -3.7,-11.2 -2,-3.9 -4.7,-7.2 -8.4,-9.7000001 C 261.6,5.861 257.1,4.461 252.2,4.061 c -1,0 -1.5,0.5 -1.6,1.6 0,0.8999999 0,1.6999999 0,2.5999999 C 250.4,27.861 250.2,47.461 250,67.061 c 0,4.1 0,8.1 0.5,12.2 0.5,5.1 1.6,10 3.8,14.6 3.6,7.7 9.5,12.7 17.6,15.2 1.6,0.5 3.4,0.7 5.1,0.9 1,0.2 1.5,-0.3 1.5,-1.5 0,-1.1 0,-2.2 0,-3.3 0,-11.1 0.2,-22.2 0.2,-33.3 0,-6.3 0.2,-12.6 0.2,-18.9 0,-3.9 0,-7.9 -0.1,-11.8 -0.1,-3.9 -0.4,-7.9 -1.1,-11.8 z" id="path8" />
    <path class="letter" d="m 348.3,22.46 c 1.1,0 2.2,0 3.3,0 10.5,0 20.9,0 31.4,0 10.5,0 20.9,0 31.4,0 1.1,0 2.2,0 3.3,0 1.6,0 2.8,-1.3 2.8,-2.9 0,-4.2 0,-8.4 0,-12.5000001 C 420.5,5.46 419.3,4.26 417.8,4.16 c -0.9,0 -1.7,0 -2.6,0 -21.4,0 -42.8,0 -64.2,0 -0.7,0 -1.5,0 -2.2,0 -0.4,0 -0.7,0 -1.1,0.1 -0.9,0.2 -1.8,1.1 -1.9,1.9999999 0,0.6 -0.2,1.2 -0.2,1.8 0,3.4000001 0,6.9000001 0,10.3000001 0,0.5 0,1 0,1.5 0.2,1.5 1.2,2.5 2.7,2.6 z" id="path9" />
    <path class="logo" d="m 330.2,81.261 c -1,0 -2,0 -2.9,0 -4.4,0 -8.9,0 -13.3,0 v -0.2 c -4.3,0 -8.6,0 -12.9,0 -0.9,0 -1.7,0 -2.6,0 -1.6,0 -3.1,0.5 -4.6,1.2 -2.8,1.5 -4.4,3.8 -4.4,7.1 0,5.8 -0.2,11.6 -0.3,17.3 0,0.6 0,1.2 0,1.8 0,0.4 0.6,0.9 1,0.9 0.1,0 0.2,0 0.4,0 5.5,0.1 11.1,-0.2 16.5,-1.4 3.1,-0.7 6.2,-1.6 9.1,-3 7.7,-3.7 12.6,-9.7 14.4,-18 0.3,-1.6 0.5,-3.2 0.6,-4.7 0,-0.6 -0.5,-1.1 -1.2,-1.1 z" id="path10" />
    <path class="letter" d="m 610.6,103.36 c -8.2,-11.6 -16.3,-23.1 -24.5,-34.7 -0.5,-0.7 -1,-1.4 -1.6,-2.1 -1,-1.2 -2.5,-1.3 -3.6,-0.2 -0.2,0.2 -0.4,0.3 -0.5,0.5 -2.9,3.4 -5.8,6.7 -8.6,10.1 -0.5,0.6 -0.8,1.2 -1.2,1.9 -0.4,0.8 -0.5,1.7 0,2.5 0.6,1.1 1.2,2.2 1.9,3.2 4.6,6.6 9.2,13.100004 13.8,19.6 0.4,0.5 0.7,1 1.1,1.5 1.1,1.5 2.6,2.3 4.3,2.6 1.1,0.2 2.2,0.2 3.3,0.2 2.1,0 4.2,0 6.3,0 2.7,0 5.4,0 8.1,0 0.6,0 1.2,-0.2 1.8,-0.4 0.7,-0.2 1.3,-1.5 0.9,-2.2 -0.5,-0.9 -1,-1.7 -1.5,-2.5 z" id="path11" />
    <path class="letter" d="m 679.1,53.06 c -4.8,-2.3 -11,-4.7 -18.6,-7.3 -2.1,-0.8 -4.4,-1.8 -6.9,-3 -4.3,-2 -7.1,-3.4 -8.6,-4.3 -3.6,-2.3 -5.4,-4.9 -5.4,-8 0,-3.1 0.8,-3.9 2.4,-5.8 1.6,-1.9 3.8,-3.5 6.6,-4.9 3.7,-1.4 7.7,-2.2 11.9,-2.2 4.2,0 7.2,0.5 10.8,1.5 3.1,0.8 6.7,2.4 11,4.8 0.5,0.3 1,0.5 1.5,0.5 0.5,0 1.1,-0.2 1.4,-0.5 l 0.2,-0.2 1.8,-2.5 c 3,-4.2 4.7,-6.9 5.2,-8 0.1,-0.2 0,-0.6 -0.3,-1.1 -0.3,-0.5 -0.7,-1 -1.2,-1.4 -2.8,-2.4000001 -5.8,-4.4000001 -8.9,-6 -3.5,-1.6 -7.6,-2.9 -12.3,-3.7 -4.7,-0.8 -9.6,-1.1 -14.5,-0.9 -6.8,0.7 -12.8,2.6 -18,5.6 -5.2,2.9999999 -9.3,6.8 -12.3,11.3 -2.9,4.5 -4.4,9.3 -4.4,14.3 0,5 1.4,9.5 4.3,14 2.4,3.4 6,6.2 10.9,8.6 4.8,2.3 11,4.7 18.6,7.3 2.1,0.8 4.4,1.8 6.9,3 4.3,2 7.1,3.4 8.6,4.3 3.6,2.3 5.4,4.9 5.4,8 0,3.1 -0.8,3.9 -2.4,5.8 -1.6,1.9 -3.8,3.5 -6.6,4.9 -3.7,1.4 -7.7,2.2 -11.9,2.2 -4.2,0 -7.2,-0.5 -10.8,-1.5 -3.1,-0.8 -6.7,-2.4 -11,-4.8 -0.5,-0.3 -1,-0.5 -1.5,-0.5 -0.5,0 -1.1,0.2 -1.4,0.5 l -0.2,0.2 -1.8,2.5 c -3,4.2 -4.7,6.9 -5.2,8 -0.1,0.2 0,0.6 0.3,1.1 0.3,0.5 0.7,1 1.2,1.400004 2.8,2.4 5.8,4.399996 8.9,5.999996 3.4,1.6 7.4,2.8 12,3.6 5.2,1.2 10,1.4 14.4,1 0.1,0 0.3,0 0.4,0 0.4,0 0.8,0 1.2,-0.1 0.3,0 0.6,0 0.9,-0.1 0,0 0,0 0.1,0 7.5,-1 13.4,-3.6 17,-5.6 0.8,-0.4 1.5,-0.8 2.2,-1.299996 1.1,-0.7 1.6,-1.1 1.6,-1.1 2.6,-1.9 4.8,-4.100004 6.5,-6.600004 2.1,-2.9 3.5,-6.1 4.2,-9.6 0.6,-2.2 0.9,-4.5 0.9,-6.8 0,-4.8 -1.4,-9.5 -4.3,-14 -2.4,-3.4 -6,-6.2 -10.9,-8.6 z" id="path12" /> 
  </svg>   
</template>

<style lang="scss" scoped>
.logo {
  fill: rgb(var(--v-theme-logo)) !important;
}
.letter {
  fill: currentColor;
}
</style>
