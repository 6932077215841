<template>
  <v-list>
    <v-list-item class="mb-2">
      <s-leak-profile-selection 
        ref="leakProfileSelectionRef"
        :model-value="leakProfile"
        @update:model-value="switchLeakProfile"
        :fetcher="mainMenuStore.leakprofileFetcher as any"
        :initial-id="router.currentRoute.value.params.leakprofileId as string"
        variant="underlined"
      />
    </v-list-item>

    <v-list-item
      :to="`/leakprofiles/${leakProfile?.id}/accounts/`"
      title="Accounts"
      prepend-icon="mdi-form-textbox-password"
      :disabled="!leakProfile"
    />
    <v-list-item
      :to="`/leakprofiles/${leakProfile?.id}/alerts/`"
      title="Alerts"
      prepend-icon="mdi-alarm-light"
      :disabled="!leakProfile"
    >
      <template #append>
        <v-badge :content="leakProfile?.open_alert_count || '0'" inline />
      </template>
    </v-list-item>
    <v-list-item
      :to="`/leakprofiles/${leakProfile?.id}/reports/`"
      title="Reports"
      prepend-icon="mdi-file-document"
      :disabled="!leakProfile"
    />
    <v-list-subheader title="Management" class="mt-8" />
    <v-divider />
    <v-list-item :to="`/leakprofiles/${leakProfile?.id}/`" title="Leak Profile" prepend-icon="mdi-card-account-details" />
    <v-list-item href="/users/self/apitokens/" title="Development" prepend-icon="mdi-code-braces-box" />

    <template v-if="auth.user.value!.is_superuser || auth.user.value!.is_partner">
      <v-list-subheader title="Admin Area" class="mt-8" /> 
      <v-divider />
      <v-list-item href="/lookup/" title="Domain Lookup" prepend-icon="mdi-magnify" />
      <v-list-item v-if="auth.user.value!.is_superuser" href="/admin/" title="Administration" prepend-icon="mdi-file-document" />
    </template>
  </v-list>
</template>

<script setup lang="ts">
const auth = useAuth();
const router = useRouter();
const mainMenuStore = useMainMenuStore();

const leakProfile = computed({
  get: () => mainMenuStore.leakProfile,
  set: (val: LeakProfile|null) => { mainMenuStore.leakProfile = val; },
});

function switchLeakProfile(val: LeakProfile|null) {
  leakProfile.value = val;

  const oldRoute = router.currentRoute.value;
  const oldLeakprofileId = oldRoute.params.leakprofileId as string|undefined;
  if (leakProfile.value && oldLeakprofileId && oldLeakprofileId !== leakProfile.value.id) {
    // Navigate to new leak profile
    let path = oldRoute.fullPath.replaceAll(oldLeakprofileId, leakProfile.value.id);
    if (Object.entries(router.currentRoute.value.params).length > 1) {
      // If user was in a sub-page, navigate to the parent page
      path = path.split('/').slice(0, 4).join('/');
      router.push(oldRoute.fullPath.replaceAll(oldLeakprofileId, leakProfile.value.id));
    }
    router.push(path);
  } else if (leakProfile.value && oldRoute.path === '/') {
    // Start page: alerts page of first leak profile
    router.replace(`/leakprofiles/${leakProfile.value.id}/accounts/`);
  }
}

const leakProfileSelectionRef = ref<any>();
const allLeakprofiles = computed<LeakProfile[]>(() => leakProfileSelectionRef.value?.leakprofiles || []);
watch([() => router.currentRoute.value.params.leakprofileId, allLeakprofiles], async () => {
  // Select initial leak profile on load
  const lpId = router.currentRoute.value.params.leakprofileId as string|undefined;
  if (lpId && leakProfile.value?.id !== lpId) {
    leakProfile.value = await $fetch(`/api/v1/leakprofiles/${lpId}/`);
  } else if (!lpId && !leakProfile.value && allLeakprofiles.value.length > 0) {
    if (router.currentRoute.value.path === '/') {
      switchLeakProfile(allLeakprofiles.value[0]!);
    } else {
      leakProfile.value = allLeakprofiles.value[0]!;
    }
  }
}, { immediate: true });
</script>
